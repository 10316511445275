<template>
  <v-main
    :class="{
      'pa-0': $route.name === 'auth-login',
      'pt-16': $route.name !== 'auth-login',
      'pl-14': $route.name !== 'auth-login' && !movilScreen,
      'pl-0': $route.name !== 'auth-login' && movilScreen
    }"
  >
    <confirmation />
    <v-container
      fluid
      :class="{
        'pa-0': $route.name === 'auth-login'
      }"
    >
      <breadcrumbs
        v-if="$route.name !== 'auth-login' && $route.name !== 'submenu-menu'"
      />
      <router-view />
    </v-container>
  </v-main>
</template>

<script>
import breadcrumbs from '@/components/navigation/Breadcrumbs.vue'
import confirmation from '@/components/globals/Confirmations.vue'
export default {
  components: {
    breadcrumbs,
    confirmation
  },
  mounted() {
    //
  },
  computed: {
    movilScreen() {
      return window.screen.width <= 1024
    }
  }
}
</script>
