<template>
  <v-dialog
    v-model="show_confirmation"
    :max-width="dinamicWidth"
    persistent
    scrollable
  >
    <v-card confirmation>
      <v-card-title :class="`${type}`">
        <span class="headline font-weight-bold">
          {{ `${title} ${model}` }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col class="pa-0 pt-2 text-center" v-html="content"> </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn text cancel @click="closeModal">
          <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">
            {{ $t('cancel') }}
          </span>
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
        <v-btn :color="type" :loading="loading" @click="confirmAction">
          <span v-show="$vuetify.breakpoint.mdAndUp" class="mr-3">
            {{ $t('confirm') }}
          </span>
          <v-icon small v-if="type == 'info'"> fas fa-save </v-icon>
          <v-icon small v-else-if="type == 'success'">fas fa-check</v-icon>
          <v-icon small v-else-if="type == 'warning'">
            fas fa-exclamation-triangle
          </v-icon>
          <v-icon small v-else>fas fa-trash</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'confirmations',
  data() {
    return {
      loading_del: false
    }
  },
  computed: {
    ...mapGetters({
      title: 'confirmation/getConfirmationTitle',
      content: 'confirmation/getConfirmationContent',
      model: 'confirmation/getConfirmationModel',
      text: 'confirmation/getConfirmationText',
      type: 'confirmation/getConfirmationType',
      loading: 'confirmation/getConfirmationLoading',
      confirmationAcceptance: 'confirmation/getConfirmationAcceptance'
    }),
    dinamicWidth() {
      let value = 400
      let wid = Math.round(`${this.title} ${this.model}`.length / 22)
      value += 120 * wid
      return value
    },
    show_confirmation: {
      get() {
        return this.$store.getters['confirmation/getConfirmationDisplay']
      },
      set(newValue) {
        this.$store.dispatch('confirmation/confirmationValue', newValue)
      }
    }
  },
  methods: {
    closeModal() {
      this.$store.dispatch('confirmation/confirmationValue', false)
    },
    async confirmAction() {
      try {
        let noti = this.text
        this.$store.dispatch('confirmation/confirmationLoading', true)
        await this.confirmationAcceptance()
        this.$store.commit('confirmation/confirmationFinished', this.model)
        this.$toast.success(noti)
        this.$store.dispatch('confirmation/confirmationValue', false)
      } catch (e) {
        this.$store.dispatch('confirmation/confirmationLoading', false)
      }
    }
  }
}
</script>
