<template>
  <div class="breadcrumbs--container">
    <v-breadcrumbs class="navigation" :items="breadcrumb">
      <template v-slot:divider>
        <v-icon x-small>fa-chevron-right</v-icon>
      </template>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          class="main-anchor-color"
          exact
          :disabled="item.disabled"
          :to="item.to"
        >
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-btn color="secondary" text @click="goBack()">
      <v-icon class="mr-1" small>fa-arrow-left</v-icon>
      {{ `${$tc('back')}` }}
    </v-btn>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      primaryDark: false
    }
  },
  methods: {
    goBack() {
      if (window.history.state !== null) {
        this.$router.go(-1)
      }
    }
  },
  mounted() {
    this.primaryDark = this.calculateColor(this.colors.primary)
  },
  watch: {
    '$route.meta.title': {
      immediate: true,
      deep: true,
      handler(val) {
        if (val !== undefined && this.breadcrumb.length > 0) {
          this.breadcrumb[this.breadcrumb.length - 1].text +=
            val === undefined ? '' : ' - ' + val
        }
      }
    },
    breadcrumb(val) {
      if (val.length > 0) {
        document.title = val[val.length - 1].text
      } else {
        document.title = 'Iprintpos Graphic'
      }
    }
  },
  computed: {
    lastPos() {
      return this.breadcrumb[this.breadcrumb.length - 1]
    },
    ...mapGetters({
      colors: 'company/getColors'
    }),
    breadcrumb: {
      get() {
        return this.$store.getters['navigation/getBreadcrumbs'](
          this.$route
        ).map((m) => ({
          ...m,
          text: this.$tc(m.text, m.number)
        }))
      }
    }
  }
}
</script>
<style lang="sass">
.breadcrumbs--container
  display: flex
  flex-direction: row
  justify-content: space-between
  width: 100%
  padding: 5px 10px
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)
  border-radius: 15px
  .v-breadcrumbs.navigation
    padding: 0 5px
    .v-breadcrumbs__item
      font-weight: bold

.theme--light
  .breadcrumbs--btn
    color: rgba(0, 0, 0, .38)

.theme--dark
  .breadcrumbs--container
    background-color: #1E1E1E
  .breadcrumbs--btn
    color: rgba(255, 255, 255, .5)
</style>
