var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',{class:{
    'pa-0': _vm.$route.name === 'auth-login',
    'pt-16': _vm.$route.name !== 'auth-login',
    'pl-14': _vm.$route.name !== 'auth-login' && !_vm.movilScreen,
    'pl-0': _vm.$route.name !== 'auth-login' && _vm.movilScreen
  }},[_c('confirmation'),_c('v-container',{class:{
      'pa-0': _vm.$route.name === 'auth-login'
    },attrs:{"fluid":""}},[(_vm.$route.name !== 'auth-login' && _vm.$route.name !== 'submenu-menu')?_c('breadcrumbs'):_vm._e(),_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }